import './src/styles/global.css'
import React from 'react';
import RootElement from './src/components/root-element';
import "prismjs/themes/prism-solarizedlight.css"
import mermaid from "mermaid";

export const wrapRootElement = ({ element }) => {
    return <RootElement>{element}</RootElement>;
};

export const onClientEntry = () => {
  mermaid.initialize({
    startOnLoad: true,
    theme: 'default',
    fontSize: 16,
    sequence: {
      diagramMarginX: 100,
      diagramMarginY: 30,
      boxTextMargin: 15,
      noteMargin: 20,
      messageMargin: 60,
      mirrorActors: true,
      actorMargin: 100,
      width: 150,
      height: 65,
      boxMargin: 10,
    },
    htmlLabels: true,
    securityLevel: 'loose'
  });
}