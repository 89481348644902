import React, { useEffect, useState, createContext, useCallback } from "react";
import { useStaticQuery, graphql } from 'gatsby'

export const AppContext = createContext()

const useNavigationData = () => {
    const data = useStaticQuery(graphql`
        query NavigationQuery {
            allNavigationData {
                nodes {
                    versions {
                        version
                        spec
                        preview
                        guides {
                            slug
                            children {
                                slug
                            }
                        }
                    }
                }
            }
        }
    `)
    return data.allNavigationData.nodes[0]
}

export const AppProvider = ({ children }) => {
    const navigationData = useNavigationData()
    
    const [versions] = useState(navigationData.versions.map(x => ({ 
        name: x.version, 
        spec: x.spec,
        preview: !!x.preview 
    })))
    
    const [version, setVersion] = useState()

    // Helper to get the default version (first non-preview version)
    const getDefaultVersion = useCallback(() => {
        const stableVersion = navigationData.versions.find(v => !v.preview)
        return stableVersion || navigationData.versions[0] // fallback to first version if somehow all are preview
    },[navigationData.versions])

    useEffect(() => {
      
        try {
            const saved = localStorage.getItem('kyckr-api-version')
            if (saved) {
                const parsedVersion = JSON.parse(saved)
                if (versions.some(v => v.name === parsedVersion.version)) {
                    setVersion(parsedVersion)
                } else {
                    setVersion(getDefaultVersion())
                    localStorage.removeItem('kyckr-api-version')
                }
            } else {
                setVersion(getDefaultVersion())
            }
        } catch (error) {
            console.warn('Error loading saved version, defaulting to first stable version:', error)
            setVersion(getDefaultVersion())
            localStorage.removeItem('kyckr-api-version')
        }
    }, [navigationData, versions, getDefaultVersion]);

    useEffect(() => {
        if (version != null) {
            try {
                localStorage.setItem('kyckr-api-version', JSON.stringify(version))
            } catch (error) {
                console.warn('Error saving version to localStorage:', error)
            }
        }
    }, [version])

    const handleVersionChange = (event) => {
        const getVersion = navigationData.versions.filter(x => x.version === event.target.value)
        setVersion(getVersion[0]);
    };

    if (version && versions) {
        return (
            <AppContext.Provider value={{ version, handleVersionChange, versions }}>
                {children}
            </AppContext.Provider>
        )
    } else {
        return null
    }
}